/* Global scrollbar styles for WebKit browsers */
::-webkit-scrollbar {
  width: 0.25rem;
  /* 4px */
  height: 0.25rem;
  /* 4px */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background-color: var(--neuro-secondary_border);
  border-radius: 0.375rem;
  /* 6px */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--neuro-darkgrey_border);
  border-radius: 0.375rem;
  /* 6px */
  background-clip: content-box;
}

/* Firefox scrollbar support */
/* * {
  scrollbar-width: thin;
  scrollbar-color: var(--neuro-darkgrey_border) var(--neuro-secondary_border);
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Inter", "Roboto Condensed", "Roboto Slab", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 1.2vw; */
  /* Use vw for relative scaling */
  /* font-size: 1rem; */
  /* 1rem = 16px */
  /* Add a fallback for high-DPI displays or zoomed-in scenarios */
  -webkit-text-size-adjust: 100%;
  /* font-size: 2vw; */
}

/* Optional: Scale font size based on screen width */
/* @media (max-width: 37.5rem) {
  body {
    font-size: 0.875rem; /* 14px */
/* Smaller screens get a slightly smaller base font size */
/* } */
/* } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* font-size: 1rem; */
  font-family: "Roboto", "Inter", "Roboto Condensed", "Roboto Slab", sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  /* colors */
  --neuro-button-bg-primary: #2581c4;
  --neuro-primary-blue: #0079e7;
  --neuro-secondary-blue: #3687d7;
  --neuro-button-bg-secondary: #8f8f8f;
  --neuro-bg-light-grey: #f6f6f6;
  --neuro-bg-light-grey-primary: #f2f2f2;
  --neuro-bg-light-grey-secondary: #f4f4f4;
  --neuro-bg-primary-seagreen: #54b895;
  --neuro-button-bg-success: #62b55a;
  --neuro-bg-darkblue-primary: #292c67;
  --neuro-input-bg-primary: #f3f3f3;
  --neuro-login-form-bg-primary: #fcfcfc;
  --neuro-primary-error: #f03232;
  --neuro-secondary-error: #ff5d39;
  --neuro-secondary-light-error: #ff650f;
  --neuro-svg-error: #eb5757;
  --neuro-primary-success: #71b816;
  --neuro-primary-warning-lite: #fac43a;
  --neuro-grey-variant1: #978e88;

  --neuro-white-text: #ffffff;
  --neuro-grey-text: #cdcdcd;
  --neuro-dark-grey-primary: #5d5453;
  --neuro-light-grey-text: #dfdfdf;
  --neuro-black-text: #000;
  --neuro-darkblue-text: #272c33;
  --neuro-hr-bg-primary: #bbbbbb;

  --neuro-secondary_border: #dcdcdc;
  --neuro-darkgrey_border: #868686;
  --neuro-grey_border: #cbcbcb;
  --neuro-light-grey_border: #d9d9d9;
  --neuro-table-head-primary: #333333;
  --neuro-light_red_border: #ffc4A4;
  --neuro-light_red_border_light: #f5c7ae;
  --neuro-light_red_background: #fff7ee;

  /* Font Weights */
  --neuro-font-weight-bold: 700;
  --neuro-font-weight-semibold: 600;
  --neuro-font-weight-medium: 500;
  --neuro-font-weight-regular: 400;
  --neuro-font-weight-small: 300;

  /* Font Sizes (in rem) */
  --neuro-font-size-giant: 6.25rem;
  /* 100px */
  --neuro-font-size-huge: 3.75rem;
  /* 60px */
  --neuro-font-size-extra-large: 2.875rem;
  /* 46px */
  --neuro-font-size-large: 2.75rem;
  /* 44px */
  --neuro-font-size-big: 2.625rem;
  /* 42px */
  --neuro-font-size-medium-plus: 2.5rem;
  /* 40px */
  --neuro-font-size-large-medium: 2.4375rem;
  /* 39px */
  --neuro-font-size-medium: 2.25rem;
  /* 36px */
  --neuro-font-size-medium-small: 2.1875rem;
  /* 35px */
  --neuro-font-size-regular: 2.125rem;
  /* 34px */
  --neuro-font-size-large-small: 1.9375rem;
  /* 31px */
  --neuro-font-size-medium-tiny: 1.875rem;
  /* 30px */
  --neuro-font-size-small: 1.75rem;
  /* 28px */
  --neuro-font-size-tiny: 1.5625rem;
  /* 25px */
  --neuro-font-size-tiny-plus: 1.5rem;
  /* 24px */
  --neuro-font-size-mini: 1.375rem;
  /* 22px */
  --neuro-font-size-medium-smallest: 1.25rem;
  /* 20px */
  --neuro-font-size-micro: 1.125rem;
  /* 18px */
  --neuro-font-size-micro-small: 1.0938rem;
  /* 17.5px */
  --neuro-font-size-smaller: 1.0625rem;
  /* 17px */
  --neuro-font-size-smaller-plus: 1rem;
  /* 16px */
  --neuro-font-size-smallest: 0.9375rem;
  /* 15px */
  --neuro-font-size-extra-small: 0.875rem;
  /* 14px */
  --neuro-font-size-x-small: 0.8125rem;
  /* 13px */
  --neuro-font-size-smallest-x: 0.75rem;
  /* 12px */
  --neuro-font-size-tiniest: 0.625rem;
  /* 10px */

  /* Line Heights (in rem) */
  --neuro-line-height-giant: 6.25rem;
  /* 100px */
  --neuro-line-height-huge: 4.375rem;
  /* 70px */
  --neuro-line-height-huge-tiny: 3.75rem;
  /* 60px */
  --neuro-line-height-extra-large: 3.5rem;
  /* 56px */
  --neuro-line-height-large: 3.375rem;
  /* 54px */
  --neuro-line-height-big: 3.25rem;
  /* 52px */
  --neuro-line-height-medium-plus: 3.125rem;
  /* 50px */
  --neuro-line-height-large-medium: 3rem;
  /* 48px */
  --neuro-line-height-medium: 2.875rem;
  /* 46px */
  --neuro-line-height-medium-small: 2.75rem;
  /* 44px */
  --neuro-line-height-regular: 2.625rem;
  /* 42px */
  --neuro-line-height-large-small: 2.5rem;
  /* 40px */
  --neuro-line-height-medium-tiny: 2.375rem;
  /* 38px */
  --neuro-line-height-small: 2.25rem;
  /* 36px */
  --neuro-line-height-tiny: 2.125rem;
  /* 34px */
  --neuro-line-height-mini: 2rem;
  /* 32px */
  --neuro-line-height-micro: 1.875rem;
  /* 30px */
  --neuro-line-height-smaller: 1.75rem;
  /* 28px */
  --neuro-line-height-smallest: 1.625rem;
  /* 26px */
  --neuro-line-height-extra-small: 1.5rem;
  /* 24px */
  --neuro-line-height-x-small: 1.375rem;
  /* 22px */
  --neuro-line-height-smallest-x: 1.25rem;
  /* 20px */
  --neuro-line-height-tiny-plus: 1.2rem;
  /* 19.2px */
  --neuro-line-height-tiniest: 1.125rem;
  /* 18px */
  --neuro-line-height-tiniest-plus: 0.975rem;
  /* 15.6px */

  /* Font Families */
  --neuro-font-family-default: "Roboto", "Inter", "Roboto Condensed",
    "Roboto Slab", "Inter", sans-serif;
  --neuro-font-family-roboto-slab: "Roboto Slab", "Roboto", "Roboto Condensed",
    "Inter", sans-serif;
  --neuro-font-family-roboto: "Roboto", "Roboto Condensed", "Roboto Slab",
    "Inter", sans-serif;
  --neuro-font-family-roboto-condensed: "Roboto Condensed", "Roboto",
    "Roboto Slab", "Inter", sans-serif;
  --neuro-font-family-inter: "Inter", "Roboto", "Roboto Condensed",
    "Roboto Slab", sans-serif;
}

/* @media (max-width: 85.375rem) {
  :root {
    font-size: 0.875rem; /* 14px */
/* Smaller screens get a slightly smaller base font size */
/* transition: font-size 0.2s ease; */
/* } */
/* } */

html {
  font-size: 16px;
}

@media (max-width: 1366px) {
  html {
    font-size: 14px;
  }
}

/* @media (min-width: 800px) and (max-width: 1366px) {
  html {
    font-size: 15px;
  }
} */

.main-react-root {
  background-color: var(--neuro-bg-light-grey-secondary);
}

.tableCell {
  font-weight: var(--neuro-font-weight-bold);
  color: var(--neuro-table-head-primary);
  font-family: var(--neuro-font-family-roboto-condensed);
}

.subTableCell {
  font-weight: var(--neuro-font-weight-regular);
  color: var(--neuro-table-head-primary);
  font-family: var(--neuro-font-family-roboto-condensed);
}

.page-heading {
  color: var(--neuro-darkblue-text) !important;
  font-family: var(--neuro-font-family-roboto-condensed) !important;
  font-size: var(--neuro-font-size-medium-smallest) !important;
  font-weight: var(--neuro-font-weight-bold) !important;
  line-height: 120% !important;
  /* margin-bottom: 1.15rem !important; */
}

.universal-link {
  font-weight: var(--neuro-font-weight-semibold);
  line-height: var(--neuro-line-height-tiny-plus);
  text-decoration: none !important;
  color: var(--neuro-button-bg-primary);
  border-bottom: 0.063rem solid var(--neuro-button-bg-primary);
  font-family: var(--neuro-font-family-roboto-condensed);
  cursor: pointer;
}

.universal-link-white {
  font-weight: var(--neuro-font-weight-regular) !important;
  line-height: 120% !important;
  text-decoration: none !important;
  color: var(--neuro-white-text) !important;
  border-bottom: 0.063rem solid var(--neuro-white-text) !important;
  font-family: var(--neuro-font-family-roboto) !important;
  cursor: pointer;
}

.no-data {
  font-weight: var(--neuro-font-weight-medium) !important;
  font-size: var(--neuro-font-size-tiny) !important;
  font-family: var(--neuro-font-family-inter) !important;
  line-height: 120% !important;
  text-align: center !important;
  color: var(--neuro-grey-text) !important;
}

.bg-primary {
  background-color: var(--neuro-bg-light-grey-secondary);
}

.primary-button {
  background-color: var(--neuro-button-bg-primary) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-white-text) !important;
  box-shadow: none !important;
}

.secondary-button {
  background-color: var(--neuro-button-bg-secondary) !important;
  border-radius: 6.25rem !important;
  box-shadow: none !important;
}

.success-button {
  background-color: var(--neuro-button-bg-success) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-white-text) !important;
  box-shadow: none !important;
}

.dark-button {
  background-color: var(--neuro-black-text) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-white-text) !important;
  box-shadow: none !important;
}

.success-gradient-button {
  background: linear-gradient(to right,
      rgba(84, 184, 149, 1),
      rgba(97, 181, 95, 1)) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-black-text) !important;
  box-shadow: none !important;
}

.warning-button {
  background-color: var(--neuro-primary-warning-lite) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-black-text) !important;
  box-shadow: none !important;
}

.error-button {
  background-color: var(--neuro-secondary-light-error) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-white-text) !important;
  box-shadow: none !important;
}

.white-bg-primary-button {
  background-color: var(--neuro-white-text) !important;
  border-radius: 0.75rem !important;
  color: var(--neuro-button-bg-primary) !important;
  box-shadow: none !important;
}

.warning-secondary-button {
  background-color: var(--neuro-secondary-light-error) !important;
  border-radius: 6.25rem !important;
  color: var(--neuro-white-text) !important;
  box-shadow: none !important;
}

.secondary-text-button {
  color: var(--neuro-black-text) !important;
  padding: 0rem !important;
}

.secondary-text-button:hover {
  background-color: transparent !important;
}

.outlined-secondary-button {
  background-color: var(--neuro-white-text) !important;
  border-radius: 1.875rem !important;
  border: 0.063rem solid var(--neuro-secondary_border) !important;
  color: var(--neuro-black-text) !important;
  box-shadow: none !important;
}

.outlined-primary-button {
  background-color: var(--neuro-white-text) !important;
  border-radius: 1.875rem !important;
  border: 0.063rem solid var(--neuro-button-bg-primary) !important;
  color: var(--neuro-button-bg-primary) !important;
  box-shadow: none !important;
}

.checkbox-primary {
  color: var(--neuro-bg-darkblue-primary) !important;
}

.input-primary>.MuiInputBase-root {
  background-color: var(--neuro-input-bg-primary) !important;
  border-radius: 0.625rem !important;
  height: 2.625rem !important;
}

.input-primary>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.input-secondary>.MuiInputBase-root {
  background-color: var(--neuro-white-text) !important;
  border-radius: 0.625rem !important;
  height: 2.375rem !important;
}

.input-secondary>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
  border: 0.063rem solid var(--neuro-secondary_border) !important;
}

.input-textarea>.MuiInputBase-root {
  background-color: var(--neuro-white-text) !important;
  border-radius: 0.625rem !important;
  /* min-height: 42px; */
}

.input-textarea>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
  border: 0.063rem solid var(--neuro-secondary_border) !important;
}

.styled-textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 0.063rem solid var(--neuro-secondary_border) !important;
  border-radius: 0.625rem !important;
  box-shadow: none;
  resize: both;
  /* Allows resizing both horizontally and vertically */
  outline: none;
  /* Removes the default focus outline */
  box-sizing: border-box;
  /* Ensures padding is included in width/height */
}

.styled-textarea:focus {
  border-color: var(--neuro-secondary_border);
  /* Changes border color on focus */
  box-shadow: none;
  /* Adds a glow effect */
}

.custom-datetime-picker {
  border-radius: 30px;
  padding: 5px;
  border: 1px solid #dcdcdc;
}

.input-secondary-textarea>.MuiInputBase-root {
  background-color: var(--neuro-bg-light-grey-secondary) !important;
  border-radius: 0.625rem !important;
  /* min-height: 42px !important; */
}

.input-secondary-textarea>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.form-background {
  background-color: var(--neuro-login-form-bg-primary);
}

.form-heading {
  font-weight: var(--neuro-font-weight-bold);
  font-size: var(--neuro-font-size-large-medium);
  font-family: var(--neuro-font-family-roboto-condensed);
  line-height: 120%;
}

.welcome-text-auth {
  color: var(--neuro-bg-darkblue-primary);
}

.forgot-password-link {
  font-size: var(--neuro-font-size-medium-smallest);
  font-weight: var(--neuro-font-weight-regular);
  text-decoration: none;
  color: var(--neuro-black-text);
  font-family: var(--neuro-font-family-roboto-condensed);
}

.horizontal-line-wrapper {
  padding-inline: 3.875rem;
}

.horizontal-line {
  height: 0.063rem;
  background-color: var(--neuro-hr-bg-primary);
  margin-bottom: 2rem;
  text-align: center;
}

.vertical-line {
  background-color: var(--neuro-black-text);
  width: 0.125rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 0.8125rem;
}

.signup-now-link {
  text-decoration: none;
  color: var(--neuro-black-text);
  font-family: var(--neuro-font-family-roboto);
}

.or-text {
  font-size: var(--neuro-font-size-medium-smallest);
  font-weight: var(--neuro-font-weight-bold);
  font-family: var(--neuro-font-family-roboto-condensed);
}

.green-line-seperator {
  width: 4.3125rem;
  height: 0.4375rem;
  background-color: var(--neuro-button-bg-success);
  margin-top: 2.0625rem;
  margin-bottom: 3.0625rem;
}

.div-as-avatar {
  width: 7.875rem;
  height: 7.875rem;
  background-color: var(--neuro-bg-light-grey-secondary);
  border-radius: 50%;
  margin-bottom: 0.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold-text {
  font-weight: var(--neuro-font-weight-bold);
}

/* dwv-related */
#dwv {
  height: 90%;
}

.button-row {
  text-align: center;
}

/* Layers */
.layerGroup {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 90%;
}

.layer {
  position: absolute;
  pointer-events: none;
}

.drawDiv {
  position: absolute;
  pointer-events: none;
}

/* rjsf form start*/
.control-label,
#root__title {
  font-size: var(--neuro-font-size-medium-smallest);
  font-family: var(--neuro-font-family-inter);
}

.rjsf>.form-group {
  overflow: hidden;
}

.rjsf>.form-group>#root {
  border: none !important;
}

.rjsf-custom-input {
  display: flex;
  flex-direction: column;
}

.rjsf-custom-input>input {
  background-color: var(--neuro-white-text);
  border-radius: 0.625rem !important;
  height: 2.875rem !important;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border: 0.063rem solid var(--neuro-input-bg-primary);
}

.rjsf-custom-input>input:focus {
  background-color: var(--neuro-white-text);
  border-radius: 0.625rem !important;
  height: 2.875rem !important;
  padding-left: 0.625rem;
  border: 0.063rem solid var(--neuro-input-bg-primary);
  outline: var(--neuro-input-bg-primary);
  box-shadow: none;
}

.text-danger {
  color: var(--neuro-primary-error);
}

.custom-select {
  display: flex;
  flex-direction: column;
}

.custom-select>select {
  background-color: transparent;
  border-radius: 0.625rem !important;
  height: 2.875rem !important;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border: 0.063rem solid var(--neuro-input-bg-primary);
}

.rjsf-custom-radio>.field-radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
}

.rjsf-custom-radio>.field-radio-group>.radio {
  height: 2.813rem;
  /* background-color: var(--neuro-white-text); */
  border-radius: 0.625rem;
  width: 100%;
}

.rjsf-custom-radio>.field-radio-group>.radio label {
  min-width: 100%;
  cursor: pointer;
  padding-left: 1.875rem;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 0.625rem;
  background-color: var(--neuro-white-text);
}

.rjsf-custom-radio>.field-radio-group>.radio label span {
  width: 100%;
}

.rjsf-custom-radio>.field-radio-group>.radio input[type="radio"]:checked+label {
  background-color: var(--neuro-button-bg-success);
  /* Change background color when radio is checked */
  color: var(--neuro-white-text);
  /* Adjust text color for better contrast */
}

.rjsf-custom-radio>.field-radio-group>.radio input {
  margin-right: 0.375rem;
}

/* rjsf form end*/

.hospitals-text {
  font-weight: var(--neuro-font-weight-small);
  font-size: var(--neuro-font-size-mini);
  line-height: 120%;
  font-family: var(--neuro-font-family-inter);
  color: var(--neuro-black-text);
}

.welcome-text-user {
  font-size: var(--neuro-font-size-medium-smallest);
  font-weight: var(--neuro-font-weight-medium);
  line-height: 120%;
  font-family: var(--neuro-font-family-inter);
  color: var(--neuro-white-text);
}

.content-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* remirror styles */
.section-title {
  font-weight: var(--neuro-font-weight-bold);
  margin-top: 1.25rem;
}

.sub-section {
  margin-left: 1.25rem;
}

.critical {
  color: var(--neuro-primary-error);
}

/* remirror style end */

/* react date picker start */
.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.custom-datepicker {
  color: var(--neuro-black-text);
  background-color: var(--neuro-white-text) !important;
  border-radius: 0.625rem !important;
  height: 2.375rem !important;
  border: 0.063rem solid var(--neuro-secondary_border) !important;
  width: 100% !important;
}

.custom-datepicker:focus {
  border-color: var(--neuro-secondary_border);
  outline: none;
}

/* react-h5-audio-player */
/* .rhap_button-clear > svg .rhap_repeat-button > svg {
  color: var(--neuro-button-bg-success);
}

.rhap_repeat-button {
  color: var(--neuro-button-bg-success);
}

.rhap_volume-indicator .rhap_progress-indicator {
  background: var(--neuro-button-bg-success);
} */

.rhap_progress-filled {
  background-color: var(--neuro-button-bg-success) !important;
}

.rhap_progress-indicator {
  background: var(--neuro-button-bg-success) !important;
}

.rhap_repeat-button {
  color: var(--neuro-button-bg-success) !important;
}

.rhap_main-controls-button {
  color: var(--neuro-button-bg-success) !important;
}

.rhap_volume-button {
  color: var(--neuro-button-bg-success) !important;
}

.rhap_volume-indicator {
  background: var(--neuro-button-bg-success) !important;
}







/* form css */


.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.header h1 {
  font-size: 24px;
  color: #333;
  font-weight: 600;
  margin-bottom: 8px;
}

.header p {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.header .line {
  width: 100%;
  height: 2px;
  background-color: #21b9a9;
  margin: 1.5em 0;
}

.content-container {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 60px;
  /* background-color: transparent; */
  padding-top: 1px !important;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar ul {
  list-style: none;
}

.sidebar li {
  margin: 20px 0;
  color: #444;
  font-size: 1.3rem;
  cursor: pointer;
}

/* .sidebar li:hover {
  color: #21b9a9;
} */

.main-content {
  flex: 1;
  /* padding: 30px; */
  padding: 20px;
  background-color: #6c6868;
  height: 100%;
  width: 100%;
  padding-left: 20px;
  overflow-y: auto;
  /* Scrolls only vertically when necessary */
}

.main-content h2 {
  font-size: 18px;
  color: #888;
  font-weight: 500;
  margin-bottom: 12px;
}

.question-container {
  /* background-color: #f9f9f9; */
  /* background-color: white;
  padding: 19px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1.5em;
  width: 90%; */




  width: 500px !important;
  height: auto;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Secondary-Neuro_white, #FFF);
}

.question {
  font-weight: bold;
  color: #333;
  font-size: 18px;
  margin-bottom: 12px;
}

.question-input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.radio-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: #444;
}

.radio-item input[type="radio"] {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.radio-item input[type="radio"] {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.radio-item input[type="checkbox"] {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.radio-item label {
  font-size: 18px;
}

.right-sidebar {
  width: 280px;
  background-color: #f8f8f8;
  padding: 20px;
  border-left: 1px solid #e0e0e0;
  overflow-y: auto;
}

.right-sidebar h3 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: 12px;
}

.right-sidebar label {
  display: block;
  font-weight: 420;
  color: #9a9696;
  margin-top: 12px;
  font-size: 15px;
}

.right-sidebar input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
  background-color: #fff;
}

.right-sidebar input[type="select"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
  background-color: #fff;
}

.right-sidebar .input-style {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
  background-color: #fff;
  color: #333;
}

.right-sidebar select {
  width: 100%;
  /* Make select dropdown take full width */
  padding: 15px;
  /* Add padding for visual balance */
  border: 1px solid #ddd;
  /* Light border for definition */
  border-radius: 4px;
  /* Rounded corners for consistency */
  font-size: 14px;
  /* Font size matching other inputs */
  margin-top: 5px;
  /* Spacing above the select dropdown */
  background-color: #fff;
  /* White background */
  color: #333;
  /* Text color */
}

.right-sidebar input[type="checkbox"] {
  margin-right: 10px;
}

.right-sidebar select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  margin-top: 5px;
}

.toggle-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.toggle-button {
  flex: 1;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  background-color: #f8f8f8;
}

.toggle-button.active {
  background-color: #21b9a9;
  color: #fff;
  border-color: #21b9a9;
}





.right-sidebar input[type="text"]:focus {
  border: 2px solid #21b9a9;
  /* Change border color on focus */
  outline: none;
  /* Removes the default outline */
}

.svc-tabbed-menu {
  display: flex;
  gap: 2rem;
  /* 2rem gap between tabs */
  justify-content: flex-start;
}

.svc-tabbed-menu-item-container {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
  /* No bottom border initially */
}

.svc-tabbed-menu-item__text {
  display: inline-block;
}

.svc-tabbed-menu-item-container:hover {
  color: #21b9a9;
  /* Color on hover */
}

.svc-tabbed-menu-item--selected {
  color: #21b9a9;
  /* Color when selected */
  border-bottom: 2px solid #21b9a9;
  /* Highlight the selected tab with a bottom border */
}

/* Sidebar styles */
.sidebar {
  width: 74px;
  /* Initial width of the sidebar */
  background-color: white;
  padding-top: 20px;
  border-right: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.3s ease-in-out;
  /* Smooth transition */
}

.sidebar.expanded {
  width: 210px;
  /* Expanded width */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.submenuitem {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: black;
}

.iconitems {
  font-size: 13px !important;
  color: black;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 7px 14px;
  cursor: pointer;
  color: #a49e9e;
  margin: 8px 0px;
  font-size: 14px;
  transition: color 0.3s ease;
}

/* Style for the options container */
.options-container {
  display: flex;
  gap: 10px;
  /* Space between buttons */

  padding: 10px;
  transition: background-color 0.3s ease;
  /* Smooth background color transition */
}

.edit-button>.icon {
  font-size: 20px;
}

.copy-button>.icon {
  font-size: 19px;
}

/* Button base styles */
.options-container button {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: transparent;
}

/* Edit button specific styles */
.edit-button {
  background-color: transparent;
  /* Green background for Edit button */
  color: black
    /* White text */
}


.edit-button:hover {
  background-color: #f3f3f3;
  /* Darker green when hovered */
  color: black;
  /* Keep text white */
}

/* Copy button specific styles */
.copy-button {
  background-color: transparent;
  /* Blue background for Copy button */
  color: black;
  /* White text */
}

.copy-button:hover {
  background-color: #f3f3f3;
  /* Darker blue when hovered */
  color: black;
  /* Keep text white */
}

/* Delete button specific styles */
.delete-button {
  background-color: transparent;
  /* Red background for Delete button */
  color: black;
  /* White text */
}

.delete-button:hover {
  background-color: #f3f3f3;
  /* Darker red when hovered */
  /* color: #fff;  */
  color: black;
}

/* Icon spacing */
.icon {
  margin-right: 5px;
  /* Space between icon and text */
}

.sidebar li.selected {
  background-color: #21b9a9;
  /* Highlight the selected item */
  color: white;

}

.sidebar li:hover {
  background-color: white;
  border-radius: 20px;
}


.sidebar li .icon {
  font-size: 12px !important;
  margin-right: 8px;
}


.hamburger-btn {
  padding: 8px 14px;
  cursor: pointer;
  color: #444;
  margin: 10px;
  font-size: 14px;
  transition: color 0.3s ease;
  align-items: center;
  justify-content: center;
}

.hamburger-btn {
  border: none;
}

.sidebar li .name {
  display: inline-block;
  font-size: 14px;
  color: #333;
  font-weight: 400;
}


/* Sidebar styles */
.right-sidebar {
  width: 280px;
  /* position: fixed; */
  top: 0;
  right: 0;
  height: 100%;
  background: #f4f4f4;
  padding: 16px;
  display: block;
  transition: transform 0.3s ease;
}


/* Hide sidebar on small screens */
@media (max-width: 800px) {
  .right-sidebar {
    display: none;
  }
}

.icon-bold {
  stroke-width: 2;
  /* Increase stroke width for bolder icons */
  color: rgb(166, 165, 165);
  /* Set color */
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  position: fixed;
  top: 16px;
  right: 16px;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

@media (max-width: 800px) {
  .hamburger-menu {
    display: block;
  }
}

/* Sidebar visible when toggled */
.right-sidebar.open {
  display: block;
}

/* Main content styling */
.main-content {
  flex: 1;
  /* padding: 15px; */
  /* background-color: #fff; */
  background-color: #f4f4f4;
  height: 100vh;
  /* Ensure height is defined */

  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-container {
  margin-bottom: 20px;
  /* Add some space between questions */
}

.question-controls {
  margin-top: 10px;
}

.formpage2 {
  width: 532px;
  height: auto;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #FFC4A4;
  background: #FFF7EE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 13rem;
}

.formpage1 {
  width: 532px;
  height: auto;
  flex-shrink: 0;
  border-radius: 12px;
  /* border: 1px solid #FFC4A4; */
  background: var(--Secondary-Neuro_lightgrey, #F4F4F4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 13rem;
}

.linepage {
  height: 2px;
  width: 80%;
  background-color: rgb(19, 177, 161);
  margin: 2px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
}


/* Base Header Styles */

.svc-tabbed-menu-item-container {
  padding: 5px 10px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}



/* Hamburger Icon Styles */
.header__hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.header__hamburger span {
  width: 25px;
  height: 3px;
  background-color: #333;
  border-radius: 2px;
}

.pagebutton {
  color: var(--Secondary-Neuro_darkgrey, #868686);

  /* H4 */
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 24px */
  position: relative;
  left: -13rem;
  top: -1rem;

}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .svc-tabbed-menu {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #f8f9fa;
    padding: 10px;
    position: absolute;
    top: 60px;
    /* Adjust as per header height */
    right: 0;
    left: 0;
    z-index: 10;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .svc-tabbed-menu--open {
    display: flex;
  }

  .header__hamburger {
    display: flex;
  }
}


.surveytitle {
  font-size: 1.2rem;
  outline: none;
  background: transparent;
  border: none;
  margin: 5px 0px;
  font-weight: 500;
  color: #9a9696;
  padding: 5px;
}

.Description {
  font-size: 16px;
  outline: none;
  background: transparent;
  border: none;
  margin: 0px;
  font-weight: 500;
  color: #9a9696;
  padding: 0px;
}






/* other component */



.question-container {
  margin-bottom: 20px;
  border: none;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 10px;
}


.question-container {

  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.question-container.active {
  border-color: orange;
}

.question-header {
  margin-bottom: 10px;
}

.radio-group .radio-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.radio-item input[type='radio'] {
  margin-right: 10px;
}

.question-controls {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-spacing {
  margin-right: 10px;
}

.radio {
  margin: 0px !important;
  height: 20px !important;
  width: 20px !important;
}

.remove-button,
.add-button {
  padding: 4px 8px;
  font-size: 18px;
  border: none;
  cursor: pointer;
}

.radio-item {
  gap: 0.4rem;
}

.remove-button {
  background-color: #f9c7c7;
  /* Red color */
  color: white;
}

.boolean-score-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 65%;
  max-width: 180px;
  height: auto;
  background-color: transparent;
  padding: 10px 15px;
  /* border: 1px solid gray; */
  border-radius: 50px;
  box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.1);
}

.boolean-score-input {
  width: 60px;
  border: 1px solid var(--neuro-black-text) !important;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.5rem;
}

.boolean-score-input:hover {
  border: 1px solid var(--neuro-secondary_border) !important;
}

.boolean-score-input:focus {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.boolean-score-input:active {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.sd-progress-buttons--bottom .sd-progress-buttons__footer .sd-progress-buttons__page-title {
  margin-bottom: 0 !important;
}

.sd-progress-buttons__list-container {
  padding-top: 30px !important;
}

.sd-progress-buttons__footer {
  display: grid !important;
  justify-content: center !important;
}

.sd-progress-buttons__footer .sd-progress-buttons__page-title {
  padding: 8px !important;
  background-color: var(--neuro-secondary-blue) !important;
  color: var(--neuro-white-text) !important;
}

.sd-progress-buttons__list .sd-progress-buttons__button[data-tooltip]:hover {
  cursor: pointer;
  position: relative;
}

.sd-progress-buttons__button {
  position: relative !important;
}

.sd-progress-buttons__list {
  position: relative !important;
}

.sd-progress-buttons__list .sd-progress-buttons__button[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  border-bottom: 5px solid #333;
}

/* Adjust progress bar button spacing */
.sd-progress-buttons__list .sd-progress-buttons__button {
  margin: 0 5px;
}

.sd-progress-buttons__list:first-child .sd-progress-buttons__button[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -35px;
  left: 50% !important;
  transform: none !important;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
}

.sd-progress-buttons__list:last-child .sd-progress-buttons__button[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  left: auto !important;
  right: 50% !important;
  transform: none !important;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
}

.survey-js-gobal-input {
  /* border: 1px solid var(--neuro-black-text) !important; */
  border-radius: 6px;
  outline: none !important;
}

.survey-js-gobal-input:hover {
  border: 1px solid var(--neuro-secondary_border) !important;
  outline: none !important;
}

.survey-js-gobal-input:focus {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.survey-js-gobal-input:active {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

label {
  font-size: 19px;
}

.add-button {
  background-color: #d8f1d9;
  /* Green color */
  color: white;
}

.editable-choice-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Vertically center items */
  margin-right: 10px;
  /* Add gap between items */
  gap: 0.8rem;
}


/* Style the dropdown select */
.dropdown-select {
  width: 40%;
  /* Make it take up the full width of its container */
  padding: 8px 12px;
  /* Add padding inside the select box */
  font-size: 14px;
  /* Set the font size */
  border-radius: 4px;
  /* Rounded corners */
  border: 1px solid #ccc;
  /* Light grey border */
  background-color: #fff;
  /* White background */
  color: #333;
  /* Dark text color */
  box-shadow: 0 2px 5px rgba(175, 174, 174, 0.1);
  /* Slight shadow for a 3D effect */
  cursor: pointer;
  /* Change cursor to pointer */
  appearance: none;
  /* Remove default dropdown styling for Webkit browsers */
  -webkit-appearance: none;
  /* Remove default dropdown styling for Safari */
  -moz-appearance: none;
  /* Remove default dropdown styling for Firefox */
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

/* Add a custom arrow icon to the select dropdown */
/* .dropdown-select::after {
  content: '\25BC';
  /* Downward triangle character */
/* position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; */
/* Allow interaction with the select without interference */
/* } */

/* Style the option items */
.dropdown-select option {
  padding: 10px;
  /* Space out the options */
  background-color: #fff;
  /* White background for each option */
  color: #333;
  /* Dark text color */
  font-size: 14px;
  /* Set the font size */
}

/* Hover effect for options */
.dropdown-select option:hover {
  background-color: #f2f2f2;
  /* Light grey background on hover */
}

.dropdown-select option:checked {
  background-color: #007bff;
  /* Change to your desired background color */
  color: white;
  /* Change to your desired text color */
  font-weight: bold;
  /* Optional: emphasize the selected options */
}

/* Focus effect on select */
.dropdown-select:focus {
  outline: none;
  /* Remove the default focus outline */
  border-color: none;
  /* Blue border color on focus */
  box-shadow: 0 0 5px rgba(190, 191, 191, 0.5);
  /* Blue glow on focus */
}

/* Optional: Style the container of the select to control layout */
.select-container {
  position: relative;
  /* To position the custom arrow inside the select */
  width: 100%;
  /* Full width */
  max-width: 300px;
  /* Limit the width */
  margin: 10px 0;
  /* Add some spacing around the select */
}

/* Styling for the question controls container */
.question-controls {
  margin: 10px;
  background-color: #f1f1f1;
  /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  display: flex;
  /* To align buttons horizontally */
  gap: 10px;
  /* Space between buttons */
}

/* Common button styles */
.button-spacing {
  padding: 5px 10px;
  /* Padding inside buttons */
  border-radius: 3px;
  /* Rounded corners */
  color: white;
  /* White text color */
  border: none;
  /* No border */
  cursor: pointer;
  /* Pointer cursor on hover */
}

/* Styling for the question controls container */
.question-controls {
  margin: 10px;
  background-color: transparent;
  /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  display: flex;
  /* To align buttons horizontally */
  gap: 10px;
  /* Space between buttons */
}


@media screen and (max-width: 600px) {
  .question-controls {
    flex-direction: column;
    /* Stack items vertically */
    gap: 12px;
    /* Adjust gap for smaller screens */
    margin-top: 10px;
    /* Reduce margin if needed */
  }
}

/* Common button styles */
.button-spacing {
  padding: 5px 10px;
  /* Padding inside buttons */
  border-radius: 3px;
  /* Rounded corners */
  color: black;
  /* White text color */
  border: 2px solid transparent;
  /* Border set to transparent by default */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: all 0.3s ease;
  /* Smooth transition for color and background */
}

.editable-text {
  font-size: 18px;
  color: var(--Secondary-Neuro_black, #000);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.edit-label-input {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid var(--neuro-black-text);
  width: 100px !important;
  outline: none !important;
  border-radius: 6px;
}

.edit-label-input:hover {
  border: 1px solid var(--neuro-secondary_border) !important;
  outline: none !important;
}

.edit-label-input:focus {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.edit-label-input:active {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}


.edit-score-input {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 60px !important;
  border: 1px solid var(--neuro-black-text);
  outline: none !important;
  border-radius: 6px;
}

.edit-score-input:hover {
  border: 1px solid var(--neuro-secondary_border) !important;
  outline: none !important;
}

.edit-score-input:focus {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.edit-score-input:active {
  border: 1px solid var(--neuro-bg-primary-seagreen) !important;
  outline: none !important;
}

.editable-text:active {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.remove-button {
  margin: 0rem !important;
}

.add-button {
  margin: 0rem !important;
}

/* Specific background colors for each button (default is transparent) */
.green-bg {
  background-color: rgb(234, 236, 236);
  /* Default is transparent */
}

.blue-bg {
  background-color: transparent;
  /* Default is transparent */
}

.red-bg {
  background-color: transparent;
  /* Default is transparent */
}

.Required {
  color: rgb(249, 111, 111);
}

/* Hover effect: changes background to light grey and text to black */
.button-spacing:hover {
  background-color: #f3f3f3;
  /* Light grey background on hover */
  color: black;
  /* Text color changes to black on hover */
  border-color: #ccc;
  /* Light border color to match the background */
}


/* Optional: Add hover effect for buttons */
.button-spacing:hover {
  opacity: 0.8;
  /* Slight opacity change on hover */
}

.edit-input {
  font-size: 16px;
  padding: 4px;
}

.validator-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.validator-field-label {
  font-size: 16px !important;
}

.numeric-input {
  border: 1px solid #000;
  padding: 8px;
  border-radius: 6px;
  width: 100%;
}

.numeric-input.input-error {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 4px;
}




/* other component */




.question-container {
  margin-bottom: 20px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox {
  margin-right: 10px;
  height: 20px !important;
  width: 20px !important;
}

.remove-button,
.add-button {
  padding: 4px 8px;
  font-size: 18px;
  border: none;
  cursor: pointer;
}

.remove-button {
  background-color: #f9c7c7;
  /* Red color */
  color: white;
}

label {
  font-size: 19px;
}

.add-button {
  background-color: #d8f1d9;
  /* Green color */
  color: white;
}


/* Styling for the question controls container */
.question-controls {
  margin: 10px;
  background-color: #f1f1f1;
  /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  display: flex;
  /* To align buttons horizontally */
  gap: 10px;
  /* Space between buttons */
}


@media screen and (max-width: 600px) {
  .question-controls {
    flex-direction: column;
    /* Stack items vertically */
    gap: 12px;
    /* Adjust gap for smaller screens */
    margin-top: 10px;
    /* Reduce margin if needed */
  }
}

/* Common button styles */
.button-spacing {
  padding: 5px 10px;
  /* Padding inside buttons */
  border-radius: 3px;
  /* Rounded corners */
  color: white;
  /* White text color */
  border: none;
  /* No border */
  cursor: pointer;
  /* Pointer cursor on hover */
}

/* Styling for the question controls container */
.question-controls {
  margin: 10px;
  background-color: transparent;
  /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  /* Rounded corners */
  display: flex;
  /* To align buttons horizontally */
  gap: 10px;
  /* Space between buttons */
}

/* Common button styles */
.button-spacing {
  padding: 5px 10px;
  /* Padding inside buttons */
  border-radius: 3px;
  /* Rounded corners */
  color: black;
  /* White text color */
  border: 2px solid transparent;
  /* Border set to transparent by default */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: all 0.3s ease;
  /* Smooth transition for color and background */
}

/* Specific background colors for each button (default is transparent) */
.green-bg {
  background-color: rgb(234, 236, 236);
  /* Default is transparent */
}

.blue-bg {
  background-color: transparent;
  /* Default is transparent */
}

.red-bg {
  background-color: transparent;
  /* Default is transparent */
}

.Required {
  color: rgb(249, 111, 111);
}

/* Hover effect: changes background to light grey and text to black */
.button-spacing:hover {
  background-color: #f3f3f3;
  /* Light grey background on hover */
  color: black;
  /* Text color changes to black on hover */
  border-color: #ccc;
  /* Light border color to match the background */
}


Style for the buttons .blue-bg {
  background-color: #3498db;
  color: white;
}

/* .red-bg {
background-color: #e74c3c;
color: white;
} */

.button-spacing {
  margin-right: 10px;
}

/* Orange color for the icons and active state */
.required-button.active {
  color: orange;
}

.required-button.active svg {
  color: orange;
}

.blue-bg,
.red-bg,
.required-button {
  color: black;
}

.blue-bg:hover,
.red-bg:hover,
.required-button:hover {
  color: #5a5b5a;
}



/* Optional: Add hover effect for buttons */
.button-spacing:hover {
  opacity: 0.8;
  /* Slight opacity change on hover */
}

.edit-input {
  font-size: 16px;
  padding: 4px;
}




.question-container {
  margin: 0;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-name-input {
  flex: 1;
  font-size: 1rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: none !important;
}


.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.question-name-input {
  padding: 8px;
  font-size: 16px;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.required {
  color: red;
  font-size: 20px;
  margin-left: 5px;
}

.file-input-container {
  border: 2px dashed #ccc;
  /* Dashed border */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.file-upload-label {
  font-size: 16px;
  color: #666;
  cursor: pointer;
}

.file-input {
  display: none;
  /* Hides the default file input */
}

.file-name {
  color: green;
  font-size: 16px;
}

.file-placeholder {
  color: #aaa;
  font-size: 16px;
}

.remove-file-button,
.duplicate-button .delete-button {
  background-color: #9e9e9e;
  /* Gray background */
  color: white;
  border: none;
  padding: 8px 12px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-file-button:hover {
  background-color: #757575;
  /* Darker gray for hover */
}

.question-controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}


@media screen and (max-width: 600px) {
  .question-controls {
    flex-direction: column;
    /* Stack items vertically */
    gap: 12px;
    /* Adjust gap for smaller screens */
    margin-top: 10px;
    /* Reduce margin if needed */
  }
}

.duplicate-button,
.delete-button,
.required-button {
  padding: 3px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.duplicate-button.gray-bg {
  background-color: #9e9e9e;
  /* Gray background */
  color: white;
}

.duplicate-button.gray-bg:hover {
  background-color: #757575;
  /* Darker gray for hover */
}

.delete-button.gray-bg {
  background-color: #9e9e9e;
  /* Gray background */
  color: white;
}

.delete-button.gray-bg:hover {
  background-color: #757575;
  /* Darker gray for hover */
}

.required-button {
  background-color: white;
  /* Gray background */
  font-weight: 500;
  font-size: 0.9rem;
  color: #ee840d;
  /* Darker gray for text */
}


.button-spacing {
  margin-right: 10px;
}

/* image picker */
.image-picker-container {
  margin: 0 auto;
  padding: 1rem;
}

.image-picker-container main {
  box-sizing: border-box;
  border: 2px solid #ccc;
  /* Visible border */
  border-radius: 8px;
  /* Rounded corners */
  background-color: #f9f9f9;
  /* Light background for contrast */
  width: 100%;
  /* Matches the width requirement */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: Add a subtle shadow */
}

.image-picker-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
}

.image-picker-item {
  position: relative;
  width: calc(50% - 1rem);
  height: 150px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.image-picker-item.selected {
  border-color: #007bff;
}

.image-picker-controls {
  margin: 1rem;
  display: flex;
  flex-direction: 'row';
  gap: 1rem;
}

.image-picker-label {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.image-picker-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 5px;
}

.circle-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.circle-button:hover {
  background-color: #e0e0e0;
}

.upload-button input {
  display: none;
}

.dynamic-switch-group {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #f9f9f9;
  width: 60%;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
}

.question-header {
  margin-bottom: 1rem;
}

.question-name-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  border-radius: 6px;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 65%;
  max-width: 180px;
  height: auto;
  background-color: transparent;
  padding: 10px 15px;
  /* border: 1px solid gray; */
  border-radius: 50px;
  box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.1);
}

.label-input {
  width: 40%;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.switch-checkbox {
  display: none;
  /* Hide default checkbox */
}

.switch-label.yes {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.switch-label.no {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.switch-checkbox:checked+.switch-label {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.question-footer {
  display: flex;
  /* justify-content: space-between;
  gap: 10px; */
  justify-content: center;
  align-items: center;
  margin-top: 1rem !important;
}

.duplicate-button,
.remove-button2 {
  padding: 0px;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.duplicate-button {
  background-color: white;
  /* Light gray color */
  color: #ee840d;
  /* Darker gray for text */
  font-weight: 500;
  border: none;
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.duplicate-button:hover {
  background-color: #e6e3e3;
  /* Slightly darker gray */
  color: #464746;
  /* Darker text on hover */

}

.remove-button2 {
  background-color: #d3d3d3;
  /* Light gray color */
  color: #333;
  /* Darker gray for text */
  border: none;
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.remove-button2:hover {
  background-color: #b0b0b0;
  /* Slightly darker gray */
  color: #000;
  /* Darker text on hover */
}

.required-checkbox {
  margin-left: 0.5rem;
  accent-color: #b0b0b0;
  /* Custom checkbox color */
  cursor: pointer;
  transition: accent-color 0.3s ease;
}

@media screen and (max-width: 600px) {
  .question-footer {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    align-items: center;
    flex-direction: column;
  }
}


.required-checkbox:hover {
  accent-color: #8c8c8c;
  /* Darker gray on hover */
}

.fullscreen-save-btn {
  cursor: pointer;
}

.fullscreen-sidebar {
  cursor: pointer;
}

/* rating css */
.question-header {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  margin-bottom: 10px;
}

.question-name-input {
  color: var(--Secondary-Neuro_black, #000);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: 1px solid transparent !important;
  /* 19.2px */
}

.required {
  color: red;
}

.rating-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-remove-controls {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  gap: 0.5rem;
}

.add-button,
.remove-button {
  padding: 0px !important;
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: transparent;
  transition: transform 0.3s ease;
}

.add-button {
  border: 1px solid var(--neuro-bg-primary-seagreen);
  color: var(--neuro-bg-primary-seagreen);
}

.remove-button {
  border: 1px solid var(--neuro-primary-error);
  color: var(--neuro-primary-error);
}

.add-button:hover {
  transform: scale(1.2);
}

.remove-button:hover {
  transform: scale(1.2);
}

.rating-values {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.rating-item {
  margin-right: 10px;
}

.rating-input {
  width: 40px;
  padding: 5px;
  text-align: center;
  margin-right: 5px;
}

.rating-text {
  font-size: 12px;
  color: #555;
}

.question-controls {
  display: flex;
  /* justify-content: space-between; */
  gap: 8px;
  margin-top: 15px;
}


@media screen and (max-width: 600px) {
  .question-controls {
    flex-direction: column;
    /* Stack items vertically */
    gap: 12px;
    /* Adjust gap for smaller screens */
    margin-top: 10px;
    /* Reduce margin if needed */
  }
}

.duplicate-button,
.delete-button,
.required-button {
  padding: 0px;
  cursor: pointer;
  color: var(--Primary-Neuro_warning, #FF650F);
  font-family: "Roboto Slab";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

/* .required-button.active {
  background-color: green;
  color: white;
} */

.button-spacing {
  margin-right: 10px;
}

.rating-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.rating-input {
  width: 40px;
  /* Adjust size as needed */
  height: 40px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Makes the input round */
  border: 1px solid #000;
  /* Border color */
  font-size: 16px;
  text-align: center;
  outline: none;
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */

  /* Adding a shadow */
  /* box-shadow: 0 4px 8px rgba(147, 145, 145, 0.1); */
  /* Light shadow */
}

/* Optional: Hover effect for input */
.rating-input:hover {
  /* box-shadow: 0 6px 12px rgba(136, 135, 135, 0.15); */
  /* Darker shadow on hover */
  border-color: #dcdcdc !important;
  outline: none;
  /* Change border color on hover */
}

.rating-input:focus {
  /* box-shadow: 0 6px 12px rgba(128, 127, 127, 0.25); */
  /* Shadow on focus */
  border-color: var(--neuro-bg-primary-seagreen) !important;
  outline: none;
  /* Blue border on focus */
}

.rating-input:active {
  /* box-shadow: 0 6px 12px rgba(128, 127, 127, 0.25); */
  /* Shadow on focus */
  border-color: var(--neuro-bg-primary-seagreen) !important;
  outline: none;
  /* Blue border on focus */
}


.css-ybhu31 {
  height: 100%;
  overflow: hidden !important;
  position: relative;
}

/* .main-content {
   height: 120%;
   overflow-y: scroll;
  } */

/* .sd-root-modern--mobile .sd-body.sd-body--static {
    padding: calc(3*(var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow: scroll  !important;
    } */



.addbuttonpage {
  width: 500px;
  height: 52px;
  display: flex;
  /* Assuming 'fl: 0' was meant for layout */
  border-radius: 12px;
  background: #FFF;
  border: none;
  box-shadow: none;
  color: var(--Primary-Neuro_blue, #2581C4);

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  /* Corresponds to 120% of 16px */
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}


.Description {
  color: var(--Secondary-Neuro_darkgrey, #868686);
  width: 50%;
  /* Caption */
  font-family: "Roboto Slab";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 15.6px */
  /* position: relative; */
  left: -9.2rem;
  top: -2rem;
}

.page-title-input {
  /* color: var(--Secondary-Neuro_darkgrey, #868686); */
  width: 50%;
  /* Caption */
  /* font-family: "Roboto Slab"; */
  /* font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; */
  /* 15.6px */
  /* position: relative; */
  /* left: -9.2rem;
  top: -2rem; */

  color: var(--neuro-darkgrey_border);
  font-family: var(--neuro-font-family-roboto-condensed);
  font-size: var(--neuro-font-size-medium-smallest);
  line-height: 120%;

  outline: none;
  background: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
}

.column-layout {
  display: flex;
  flex-direction: column;
}

.column-layout button {
  margin-bottom: 10px;
}

.column-layout .formpage {
  margin-bottom: 20px;
}

.sd-btn {
  margin-bottom: 0 !important;
}

.fullscreen-discard-btn {
  cursor: pointer;
}

.css-1iv3sgp {
  padding: 1rem;
  background-color: var(--neuro-white-text);
  border-radius: 0.625rem;
  width: 599px;
  height: 580px !important;
  margin-left: -13px;
  /* margin-top: -10px; */
}

div[data-name="maxScore"] {
  display: none;
}

div[data-name="totalScore"] {
  display: none;
}

.surveyformviewer {
  height: auto;
  width: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

/* survey-js form */
.sd-header__text .sd-title {
  text-align: center !important;
  color: #000 !important;
}

.sd-title.sd-container-modern__title {
  padding: 0 !important;
  box-shadow: none !important;
  overflow: hidden !important;
}

.sd-root-modern .sd-container-modern__title {
  background-color: transparent !important;
}

.sd-row__question {
  border-radius: 0px !important;
}

.sd-page {
  padding: 0 !important;
}

.sd-body.sd-body--static .sd-body__navigation.sd-action-bar {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 36px !important;
  border-top: 1px solid #dcdcdc;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.sd-body.sd-body--static .sd-body__navigation {
  padding: 0 !important;
  padding-top: 0 !important;
}

.sd-body__navigation.sd-action-bar {
  padding: 0 !important;
}

.sd-body.sd-body--static {
  padding: 0 !important;
  padding-top: 33px !important;
  padding-bottom: 33px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin-top: 54px !important;
  border-radius: 25px !important;
  background-color: #fff;
}

.sd-element--with-frame {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 33px !important;
  box-shadow: none !important;
}

.sd-element--with-frame.sd-question--title-top {
  padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 33px !important;
}

.sd-btn {
  background-color: #FF650F !important;
  border-radius: 100px !important;
  color: #fff !important;
  box-shadow: none !important;
  height: 48px !important;
  display: flex;
  align-items: center;
  padding-block: 0 !important;
}

.sd-element--with-frame.sd-question--title-top>.sd-question__erbox--above-question {
  width: 100% !important;
}

.sd-element--with-frame>.sd-element__erbox {
  margin: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}